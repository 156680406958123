import React, {createElement } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiGithubpages ,
  SiRender ,
  SiFirebase
} from "react-icons/si";

function HostingPlatforms() {
  const tools= [
    {
      logo:SiGithubpages,
      text: "GitHub Pages"
    },
    {
      logo:SiVercel,
      text: "Vercel"
    },
    {
      logo:SiRender,
      text: "Render"
    },
    {
      logo:SiFirebase,
      text: "Firebase"
    },
  ];
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {tools.map((tool)=>(
        <Col xs={4} md={2} className="tech-icons">
        {createElement(tool.logo, null)}
        <p className="fs-6">{tool.text}</p>
      </Col>
      ))}
      
    </Row>
  );
}

export default HostingPlatforms;
