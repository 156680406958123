import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

const EducationCard = ({ education }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work experience-timeline"
      contentStyle={{
        background: "#1d1836",
        color: "#fff",
      }}
      contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
      date={education.duration}
      iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
      icon={
        <div className="experience-icon">
          <img
            src={education.logo}
            alt={education.logo}
            className="experience-logo"
          />
        </div>
      }
    >
      <h3 className="vertical-timeline-element-title experience-role">
        {education.from}
      </h3>
      <h6 className="vertical-timeline-element-subtitle experience-company">
        {education.type}
      </h6>
      <p>
        Percentage: {education.percentage}
      </p>
    </VerticalTimelineElement>
  );
};

const Education = () => {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Education </strong>
        </h1>
      </Container>

      <VerticalTimeline>
        <EducationCard
          education={{
            from: "ABES Engineering College",
            type: "B.Tech",
            duration: "Jul 2018 - Jun 2022",
            logo: logo,
            percentage: "89 %",
          }}
        />
        <EducationCard
          education={{
            from: "Delhi Public School",
            type: "Intermediate",
            duration: "Jul 2017 - Mar 2018",
            logo: logo,
            percentage: "92.2 %",
          }}
        />
        <EducationCard
          education={{
            from: "ST Francis School",
            type: "High School",
            duration: "Apr 2015 - Mar 2016",
            logo: logo,
            percentage: "91.2 %",
          }}
        />
      </VerticalTimeline>
    </Container>
  );
};

export default Education;
