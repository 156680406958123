import React, {createElement } from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiFirebase,
  SiFlutter,
} from "react-icons/si";

function Techstack() {
  const skills = [
    {
      logo:  DiJavascript1 ,
      text: "Javascript"
    },
    {
      logo: DiReact,
      text: "React.js"
    },
    {
      logo: DiNodejs,
      text: "Node.js"
    },
    {
      logo: DiMongodb,
      text: "MongoDB"
    },
    {
      logo: CgCPlusPlus,
      text: "C++"
    },
    {
      logo: DiJava,
      text: "Java"
    },
    {
      logo: SiFlutter,
      text: "Flutter"
    },
    {
      logo: SiFirebase,
      text: "Firebase"
    },
    {
      logo: DiGit,
      text: "Github"
    },
  ];
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {skills.map((skill) => (
        <Col xs={4} md={2} className="tech-icons">
          {createElement(skill.logo, null)}
          <p className="fs-6">{skill.text}</p>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
