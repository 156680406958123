import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/home1.png";
import HostingPlatforms from "./HostingPlatforms";
import Education from "../Education/Education";
import StarsCanvas from "../Stars";
import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";
import { Helmet } from "react-helmet";

function About() {
  return (
    <Container fluid className="about-section">
      <Helmet>
        <title>Teekam Singh - About</title>
        <link rel="canonical" href="https://www.singhteekam.in/about" />
      </Helmet>
      <StarsCanvas />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I'M</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">Skilled </strong> in
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Hosting Platform</strong> I use
        </h1>
        <HostingPlatforms />

        <Education />

        {/* <Github /> */}

        <div className="prev-next">
          <Link className="btn btn-outline-success prevnext-btn" to="/">
            <FaArrowLeft /> Home
          </Link>

          <Link className="btn btn-outline-success prevnext-btn" to="/projects">
            Projects <FaArrowRight />
          </Link>
        </div>
      </Container>
    </Container>
  );
}

export default About;
