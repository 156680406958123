import React, { useState, useEffect } from "react";
import Preloader from "./components/PreLoader";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Experience from "./components/Experience/Experience";
import { Helmet } from "react-helmet";
import myImage from "./Assets/myimg.jpg";

function App() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Helmet>
        <meta
          name="description"
          content="This is my personal portfolio website. I’m currently working in Tata Consultancy Services having 2+ year of Experience and completed my B.Tech from ABES
  Engineering College, Ghaziabad. I'm skilled in React.js, Node.js, MongoDB, Flutter, Firebase, etc."
        />
        <title>Teekam Singh</title>

        <meta itemprop="name" content="Teekam Singh | Portfolio" />
        <meta
          itemprop="description"
          content="I’m Teekam Singh currently working in Tata Consultancy Services(TCS) having 2+ year of Experience."
        />
        <meta itemprop="image" content="%PUBLIC_URL%/mylogo.png" />

        <meta
          name="keywords"
          content="singhteekam, teekamsingh, teekam, singh_teekam, TeekamSingh, Teekam Singh, singh__teekam, Mathura, singht, portfolio, abes, tcs, engineer, developer, software, bloggers, brainquiz, mydiary, zerocross, blogging, blogs, technicalblogs, bloggerspace, bloggingwebsite, articles, dps, sfs "
        />
        <meta name="apple-mobile-web-app-title" content="Teekam Singh" />

        <meta property="og:title" content="Teekam Singh | Portfolio" />
        <meta
          property="og:description"
          content="This is my personal portfolio website. I’m currently working in Tata Consultancy Services having 2+ year of Experience and completed my B.Tech from ABES
  Engineering College, Ghaziabad. I'm skilled in React.js, Node.js, MongoDB, Flutter, Firebase, etc."
        />
        <meta property="og:image" content={myImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Teekam Singh | Portfolio" />
        <meta
          name="twitter:description"
          content="This is my personal portfolio website. I’m currently working in Tata Consultancy Services having 2+ year of Experience and completed my B.Tech from ABES
  Engineering College, Ghaziabad. I'm skilled in React.js, Node.js, MongoDB, Flutter, Firebase, etc."
        />
        <meta name="twitter:image" content={myImage} />
        <meta name="twitter:site" content="@singh__teekam" />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Teekam Singh | Portfolio" />
        <meta
          property="og:description"
          content="I’m Teekam Singh currently working in Tata Consultancy Services(TCS) having 2+ year of Experience."
        />
        <meta property="og:image" content={myImage} />

        <link rel="canonical" href="https://www.singhteekam.in/" />

        <script
          charset="utf-8"
          src="//cdn.iframe.ly/embed.js?api_key=0737793c1d093bd321e4d7"
        ></script>
      </Helmet>

      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
