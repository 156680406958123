import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ImPointRight } from "react-icons/im";

const ExperienceCard = ({experience}) => {
  return (
    <VerticalTimelineElement
          className="vertical-timeline-element--work experience-timeline"
          contentStyle={{
            background: "#1d1836",
            color: "#fff",
          }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date={experience.date}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<div className='experience-icon'>
            <img
              src={experience.logo}
              alt={experience.logo}
              className='experience-logo'
            />
          </div>}
        >
          <h3 className="vertical-timeline-element-title experience-role">{experience.role}</h3>
          <h6 className="vertical-timeline-element-subtitle experience-company">{experience.company}</h6>
          <p>
            
            {experience.description.map((point, index)=>(
                <p key={index} >
                    <ImPointRight /> {point}
                </p>
            ))}
            
          </p>
        </VerticalTimelineElement>
  )
}

export default ExperienceCard;
